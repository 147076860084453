//sliders
export const FETCH_SLIDER = 'FETCH_SLIDER';
export const ADD_SLIDER = 'ADD_SLIDER';


//category
export const FETCH_CATEGORY_AND_SUBCATEGORY = 'FETCH_CATEGORY_AND_SUBCATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const ADD_SUBCATEGORY = 'ADD_SUBCATEGORY';
export const DELETE_SUBCATEGORY = 'DELETE_SUBCATEGORY';